import EmployeeRepository from '@app/api/repositories/employee-repository'
import {
  IEmployee,
  IOrganizationEmployee,
} from '@app/interfaces/employee/employee'
import { ILang } from '@app/interfaces/languages'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  refreshEmployee,
  updateEmployee,
} from '@app/store/actions/employee-actions'
import { Button, Form, Input, Modal, Select, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  _employeeRates?: any[]
  language?: ILang
  employee: IOrganizationEmployee
}

const EmployeeChangeRateForm: React.FC<props> = ({
  _employeeRates,
  language,
  employee,
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      employeePositionId: employee.id ? employee.id : undefined,
    }
    setConfirmLoading(true)

    EmployeeRepository.changeRate(newValues)
      .then((response: AxiosResponse<IEmployee>) => {
        dispatch(updateEmployee(response.data))
        dispatch(refreshEmployee())
        openNotificationWithIcon('success', t('employee.successChangeRate'))
        setConfirmLoading(false)
        hideModal()
      })
      .catch(() => {
        openNotificationWithIcon('warning', t('employee.changeRateError'))
        setConfirmLoading(false)
      })
  }
  return (
    <>
      <React.Fragment>
        <Button onClick={showModal} type={'link'}>
          {t('employee.changeRate')}
        </Button>

        <Modal
          open={visible}
          title={t('employee.changeRate')}
          onCancel={hideModal}
          footer={[
            <React.Fragment key={1}>
              <ModalFooter
                edit={true}
                confirm={confirmLoading}
                onCancel={hideModal}
                onSubmit={form.submit}
              />
            </React.Fragment>,
          ]}
        >
          <Spin spinning={confirmLoading}>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{ employeeId: employee.employee?.id }}
              layout={'vertical'}
            >
              <Form.Item hidden={true} name={'employeeId'}>
                <Input />
              </Form.Item>
              <Form.Item
                label={t('pupils.orderNo')}
                name={'orderNo'}
                rules={[
                  {
                    transform: (value) =>
                      typeof value === 'string' ? value.trim() : undefined,
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                ]}
              >
                <Input disabled={confirmLoading} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                ]}
                name={'employeeRateDictionaryId'}
                label={t('employeeRate.employeeRate')}
              >
                <Select>
                  {_employeeRates?.map((status) => (
                    <Select.Option key={status.id} value={status.id}>
                      {status.data?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    </>
  )
}

export default EmployeeChangeRateForm
